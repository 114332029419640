import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const TutorialsList = () => {
  const data = useStaticQuery(
    graphql`
      query TutorialsList {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { categoryid: { eq: "tutorials" } } }) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
                description
                date(formatString: "dddd DD MMMM YYYY")
              }
            }
          }
        }
      }
    `
  );

  const { allMarkdownRemark } = data;

  return (
    <>
      {allMarkdownRemark.edges.map(({ node }) => (
        <Link to={node.fields.slug} className="button list-item--tutorial">
          <h2 className="list-item__text">{node.frontmatter.title}</h2>
          <p className="list-item__text list-item--tutorial__description"> {node.frontmatter.description}</p>
        </Link>
      ))}
    </>
  );
};

export default TutorialsList;

import React from "react";
import Seo from "../components/seo";
import Default from "../components/default";
import TutorialsList from "../components/lists/tutorialsList";
import Breadcrumbs from "../components/breadcrumbs";
import { Link } from "gatsby";

const TutorialsPage = () => {
  return (
    <>
      <Seo title="Tutorials" themeColor="#0f7037" />
      <Default>
        <section>
          <Breadcrumbs>
            <span>Tutorials</span>
          </Breadcrumbs>
          <TutorialsList />
        </section>
      </Default>
    </>
  );
};

export default TutorialsPage;
